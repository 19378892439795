import React from "react";
import styled from "styled-components";
import WidgetOptions from "./WidgetOptions";
import WidgetCode from "./WidgetCode";

const WidgetWrapper = ({
  children,
  handleCallback,
  filterValue,
  options,
  name,
  attr,
}) => {
  return (
    <WidgetContainer>
      <Container option={options}>
        {options && (
          <WidgetOptionsWrapper>
            <WidgetOptions
              handleCallback={handleCallback}
              filterValues={filterValue}
              attr={attr}
              name={name}
            />
          </WidgetOptionsWrapper>
        )}
        <WidgetCodeWrapper>
          <WidgetCode name={name} attr={attr} />
        </WidgetCodeWrapper>
      </Container>

      {children}
    </WidgetContainer>
  );
};

export default WidgetWrapper;

const WidgetContainer = styled.section`
  padding: 2.8rem 0;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    position: absolute;
    max-width: 1300px;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #dfe1e5;
  }
`;
const WidgetOptionsWrapper = styled.div``;
const WidgetCodeWrapper = styled.div``;

const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0.8rem 0;
  display: flex;
  justify-content: ${(props) => (props.option ? "space-between" : "flex-end")};
  margin-bottom: 1.2rem;
`;
