import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const renderWidget = (id, widget) => {
  if (document.getElementById(`${id}`)) {
    ReactDOM.render(
      <React.StrictMode>{widget}</React.StrictMode>,
      document.getElementById(`${id}`)
    );
  }
};

renderWidget("root", <App />);

// renderWidget(
//   "eventWidget",
//   <Event
//     dataValue={JSON.parse(
//       JSON.stringify(document.getElementById("eventWidget").dataset)
//     )}
//   />
// );

// renderWidget(
//   "reviewWidget",
//   <Review
//     dataValue={JSON.parse(
//       JSON.stringify(document.getElementById("reviewWidget").dataset)
//     )}
//   />
// );

// renderWidget(
//   "gardenWidget",
//   <Garden
//     dataValue={JSON.parse(
//       JSON.stringify(document.getElementById("gardenWidget").dataset)
//     )}
//   />
// );
