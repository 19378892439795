import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
      font-size: 10px;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #333;

    iframe{
      display: none !important;
    }

    main{
      padding: 5vw;
      overflow: hidden;
    }
  }

  img{
      max-width: 100%;
  }

  button{
    cursor: pointer;
  }

`;
export default GlobalStyle;
