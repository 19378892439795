import React, { useState } from "react";
import { debounce } from "./common/hg_helper";
import styled from "styled-components";
import Logo from "./images/hg-logo.png";
import WidgetWrapper from "./components/WidgetWrapper/WidgetWrapper.js";
import GlobalStyle from "./globalStyles.js";
import CardGrid from "./widgets/CardGrid/CardGrid.js";

const App = () => {
  const [dataValue, setDataValue] = useState({
    slug: "",
    type: "",
    rating: "",
  });
  const handleChange = (e) => {
    // if (!dataValue.slug.length) {
    //   setDataValue({ ...dataValue, slug: "" });
    // }
    setDataValue({ ...dataValue, slug: `%${e.target.value.trim()}%` });
  };
  return (
    <>
      <GlobalStyle />
      <MainContainer>
        <SearchWrapper>
          <div className="searchLogo">
            <img src={Logo} alt="" />
          </div>
          <p>
            <input
              type="search"
              placeholder="Search"
              onChange={debounce((e) => handleChange(e), 1000)}
            />
          </p>
        </SearchWrapper>
        <WidgetWrapper>
          <CardGrid
            dataValue={{
              layout: "list",
              cardtype: "event",
              hidenodatastate: false,
              title: "Events",
            }}
          />
        </WidgetWrapper>
        <WidgetWrapper>
          <CardGrid
            dataValue={{
              layout: "list",
              cardtype: "offering",
              hidenodatastate: true,
              title: "Offerings",
            }}
          />
        </WidgetWrapper>
        <WidgetWrapper>
          <CardGrid
            dataValue={{
              layout: "grid",
              cardtype: "garden",
              hidenodatastate: true,
              title: "Gardens",
            }}
          />
        </WidgetWrapper>
      </MainContainer>
    </>
  );
};

export default App;

const MainContainer = styled.main``;

const SearchWrapper = styled.div`
  max-width: 1300px;
  margin: 4rem auto;
  padding: 1.2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .searchLogo {
    margin-bottom: 3.2rem;

    img {
      display: block;
      width: 20rem;
    }
  }

  p {
    max-width: 700px;
    width: 50%;
    border-radius: 50px;
    border: 1px solid #dfe1e5;
    overflow: hidden;
    &:hover {
      box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
      border-color: rgba(223, 225, 229, 0);
    }
    input {
      padding: 1.2rem;
      display: block;
      width: 100%;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
`;
