import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { FiChevronDown } from "react-icons/fi";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import Prism from "prismjs";
import "../../prism.css";

const WidgetCode = ({ name, attr }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const node = useRef();

  const handleOptionDropdown = () => {
    setShowDropdown((showDropdown) => !showDropdown);
  };

  const closeDropDown = () => {
    setShowDropdown(false);
  };

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    closeDropDown();
  };

  const code = `
<!--Add this whereever you want this widget to render on your website-->

<div id="${name}Widget" ${
    attr?.type && name === "event" ? `data-type="${attr?.type}"` : ""
  } ${
    attr?.rating && name === "review" ? `data-rating="${attr?.rating}"` : ""
  }></div>

<!--Add this just above the closing of <body> tag on your website-->
<script src="${window.location.href}static/js/${name}.js"></script>`;

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    document.querySelector(".copyBtn span").classList.add("fadeInOut");

    setTimeout(() => {
      document.querySelector(".copyBtn span").classList.remove("fadeInOut");
    }, 850);
  };

  useEffect(() => {
    Prism.highlightAll();
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, [attr]);

  return (
    <WidgetCodeWrapper show={showDropdown} ref={node}>
      <button onClick={handleOptionDropdown}>
        Get Code <FiChevronDown />
      </button>
      <div className="codeContainer">
        <p>
          To add this widget to your website please copy below code to your
          website
        </p>
        <pre>
          <button className="copyBtn" onClick={handleCopy}>
            <span>Copied</span>
            <HiOutlineClipboardCopy />
          </button>
          <code className="language-html">{code}</code>
        </pre>
      </div>
    </WidgetCodeWrapper>
  );
};

export default WidgetCode;

const fadeInOut = keyframes`
 0% {opacity: 0; transform:translateX(50%)}
 10% {opacity: 1; transform:translateX(-2px)}
 90% {opacity: 1;  transform:translateX(-2px)}
  100% {opacity: 0;  transform:translateX(-100%)}
`;

const WidgetCodeWrapper = styled.div`
  position: relative;

  & > button {
    position: relative;
    z-index: 100001;
    display: flex;
    align-items: center;
    /* background: #008489; */
    background: #fff;
    color: #008489;
    border-radius: 5px;
    border-bottom-left-radius: ${(props) => (props.show ? "0" : "5px")};
    border-bottom-right-radius: ${(props) => (props.show ? "0" : "5px")};
    border: 1px solid rgba(223, 225, 229, 1);
    border-bottom: ${(props) =>
      props.show
        ? "1px solid transparent"
        : "1px solid rgba(223, 225, 229, 1)"};
    padding: 1rem 2rem;
    font-size: 1.5rem;

    & > svg {
      width: 2rem;
      height: 2rem;
      margin-left: 0.8rem;
      transition: 200ms ease;
      transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0deg)")};
    }
  }

  .codeContainer {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 300px;
    min-height: 200px;
    transform: translateY(-1px);
    background: #fff;
    opacity: ${(props) => (props.show ? "1" : "0")};
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    z-index: ${(props) => (props.show ? "10000" : "-99999")};
    border-color: rgba(223, 225, 229, 0);
    border: 1px solid rgba(223, 225, 229, 1);
    border-radius: 5px;
    border-top-right-radius: ${(props) => (props.show ? "0" : "5px")};
    padding: 1.6rem;
    padding-bottom: 1.4rem;

    & > p {
      margin-bottom: 1.2rem;
    }

    & > pre {
      background: #000;
      position: relative;
      font-size: 1.5rem;
      .copyBtn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        padding: 0.4rem;
        border: none;

        & > span {
          position: absolute;
          background: #fff;
          padding: 0.4rem;
          top: 0%;
          right: 0;
          opacity: 0;
          display: block;
          right: 100%;
        }
        & > span.fadeInOut {
          animation: ${fadeInOut} 800ms linear forwards;
        }

        svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }
`;
