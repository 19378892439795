import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FiChevronDown } from "react-icons/fi";

const WidgetOptions = ({ handleCallback, attr, name, filterValues }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const node = useRef();
  const handleOptionDropdown = () => {
    setShowDropdown((showDropdown) => !showDropdown);
  };

  const closeDropDown = () => {
    setShowDropdown(false);
  };

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    closeDropDown();
  };

  useEffect(() => {}, [attr]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);
  const expBtnName = ["Events", "Offerings"];
  return (
    <WidgetOptionsWrapper show={showDropdown} ref={node} attr={attr}>
      <button onClick={handleOptionDropdown}>
        Options <FiChevronDown />
      </button>
      <div className="optionContainer">
        {<p>Filter {name === "event" ? "experinces" : name} by:</p>}
        <p>
          {filterValues.map((btn, index) => {
            return (
              <button
                onClick={() => {
                  handleCallback(btn);
                }}
                key={btn + index}
              >
                {name === "event" ? expBtnName[index] : btn}
              </button>
            );
          })}
        </p>
      </div>
    </WidgetOptionsWrapper>
  );
};

export default WidgetOptions;

const WidgetOptionsWrapper = styled.div`
  position: relative;

  & > button {
    position: relative;
    z-index: 11;
    display: flex;
    align-items: center;
    /* background: #008489; */
    background: #fff;
    color: #008489;
    border-radius: 5px;
    border-bottom-left-radius: ${(props) => (props.show ? "0" : "5px")};
    border-bottom-right-radius: ${(props) => (props.show ? "0" : "5px")};
    border: 1px solid rgba(223, 225, 229, 1);
    border-bottom: ${(props) =>
      props.show
        ? "1px solid transparent"
        : "1px solid rgba(223, 225, 229, 1)"};
    padding: 1rem 2rem;
    font-size: 1.5rem;

    & > svg {
      width: 2rem;
      height: 2rem;
      margin-left: 0.8rem;
      transition: 200ms ease;
      transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0deg)")};
    }
  }

  .optionContainer {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 300px;
    transform: translateY(-1px);
    background: #fff;
    opacity: ${(props) => (props.show ? "1" : "0")};
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    z-index: ${(props) => (props.show ? "10" : "-99999")};
    border-color: rgba(223, 225, 229, 0);
    border: 1px solid rgba(223, 225, 229, 1);
    border-radius: 5px;
    border-top-left-radius: ${(props) => (props.show ? "0" : "5px")};
    padding: 2rem;
    padding-top: 1.6rem;

    & > p {
      font-weight: 500;
      font-size: 1.5rem;

      & > button {
        font-weight: 500;
        font-size: 1.4rem;
        padding: 0.4rem 0.8rem;
        background: hsla(182, 100%, 27%, 0.2);
        color: hsla(182, 100%, 27%, 1);
        border: none;
        transition: 150ms ease;
        outline-offset: 2px;

        &:hover {
          background: hsla(182, 100%, 27%, 0.3);
          color: hsla(182, 100%, 23%, 1);
        }
      }

      /* & > button:nth-of-type(1) {
        outline: ${(props) =>
        props.attr === "service"
          ? "2px solid hsla(182, 100%, 27%, 0.8)"
          : "none"};
      }
      & > button:nth-of-type(2) {
        outline: ${(props) =>
        props.attr === "class"
          ? "2px solid hsla(182, 100%, 27%, 0.8)"
          : "none"};
      } */
      & > button:not(:last-of-type) {
        margin-right: 1.2rem;
      }
    }
    & > p:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;
